import React from 'react'
import styled from 'styled-components'

const StyledH2 = styled.h2`
  color: #333;
  font-size: 90px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, 'Segoe UI', Arial, sans-serif;
  @media (max-width: 768px) {
    font-size: 35px;
  }
`

const H2 = ({ children }) => <StyledH2>{children}</StyledH2>

export default H2
