import React from 'react'
import styled from 'styled-components'

const PageHeaderContainer = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 200px;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    height: auto;
  }
`

const PageHeader = ({ children }) => (
  <PageHeaderContainer>{children}</PageHeaderContainer>
)

export default PageHeader
