import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import posed from 'react-pose'
import { TransitionState } from 'gatsby-plugin-transition-link'
import styled from 'styled-components'
import Page from '../components/page'
import H2 from '../components/h2'
import Button from '../components/button'
import IntroSection from '../components/introSection'
import Footer from '../components/footer'
import PageHeader from '../components/pageHeader'

const PageTransitionStates = posed.div({
  startState: {
    opacity: 1,
    y: 0,
  },
  exitedState: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 300,
    },
  },
})

const OverlayTransitionStates = {
  startState: {
    opacity: 1,
    transition: {
      duration: 300,
    },
  },
  exitedState: {
    opacity: 1,
    delay: 300,
    applyAtStart: { display: 'block' },
    transition: {
      duration: 300,
    },
  },
  visible: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
    transition: {
      duration: 300,
    },
  },
}

const photosTransitionStates = {
  // startState: {
  //   opacity: 0,
  // },
  // exitedState: {
  //   opacity: 0,
  //   transition: {
  //     duration: 600,
  //     ease: 'easeIn',
  //   },
  // },
  // visible: {
  //   opacity: 1,
  //   transition: {
  //     delay: 1500,
  //     duration: 600,
  //   },
  // },
}

const TransitionOverlay = styled(posed.div(OverlayTransitionStates))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #f6f6f6;
  opacity: 1;
`

const End = styled.div`
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 200px;
  margin-bottom: 3rem;
  margin: 10rem auto;
  max-width: 500px;
  text-align: center;
`

const ContentPage = styled.div`
  margin: 10.55rem auto 0;
  padding: 1.45rem 0 0;
  @media (max-width: 768px) {
    margin: 5rem 0 0;
  }
`

const PhotographyPadding = styled.div`
  padding: 0 1.55rem 0rem;
  @media (max-width: 768px) {
    padding: 0 2rem 0rem;
  }
`

const StyledImg = styled(Img)`
  width: 100%;
  border-radius: 50px;
  @media (max-width: 1024px) {
    width: 100%;
    border-radius: 25px;
  }
`

const TwoPhotos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 0.75rem;
  margin: 0 0.75rem 0.75rem;
  justify-content: space-between;
  :hover {
    position: relative;
    z-index: 5;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
`

const OnePhoto = styled(Img)`
  border-radius: 50px;
  margin: 0 0.75rem 0.75rem;
  @media (max-width: 1024px) {
    border-radius: 25px;
  }
`

const Photos = styled(posed.div(photosTransitionStates))`
  margin-top: 5rem;
`

const Buttons = styled.div`
  margin-top: 3rem;
`

const ButtonsTop = styled.div`
  @media (max-width: 768px) {
    display: block;
    width: calc(100% - 2rem);
  }
`

const TitleContainer = styled.div`
  @media (max-width: 768px) {
    width: calc(100% - 4rem);
  }
`

const IntroText = styled.p`
  color: #555555;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', Helvetica Neue,
    Helvetica, Arial, sans-serif;
  max-width: 600px;
  line-height: 1.5em;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  a,
  a:visited {
    color: #555555;
    background-image: linear-gradient(
      to bottom,
      transparent 65%,
      rgba(50, 50, 50, 0.25) 0% /* rgba(35, 86, 155, 0.75) 10% */
    );
    background-repeat: no-repeat;
    text-decoration: none;
    transition: background-position 0.2s ease;
    background-size: 100% 250%;
    background-position: 0% 65%;
    :hover {
      background-position: 0% 100%;
      cursor: pointer;
    }
  }
`

function getTransitionStates(status) {
  if (['entering', 'entered'].includes(status)) {
    return 'visible'
  } else if (['exiting'].includes(status)) {
    return 'exitedState'
  } else if (['exited'].includes(status)) {
    return 'startState'
  } else if (['POP'].includes(status)) {
    return 'visible'
  }
}

class PageTwo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hovered: 0,
    }
  }

  focusPhoto(e) {}

  render() {
    return (
      <Page>
        <ContentPage>
          <TransitionState>
            {({ transitionStatus: status }) => {
              return (
                <PageTransitionStates pose={getTransitionStates(status)}>
                  <PageHeader>
                    <TitleContainer>
                      <H2>Photography</H2>
                    </TitleContainer>
                    <ButtonsTop>
                      <Button
                        onPressed={() => {
                          window.open('https://www.instagram.com/oscrse')
                        }}
                        showArrow={true}
                      >
                        Instagram
                      </Button>
                      <Button
                        onPressed={() => {
                          window.open('https://unsplash.com/@oscrse')
                        }}
                        showArrow={true}
                      >
                        Unsplash
                      </Button>
                    </ButtonsTop>
                  </PageHeader>
                  <PhotographyPadding>
                    <IntroSection>
                      <IntroText>
                        Currently I use a Sony A7 or my iPhone. My shooting
                        style is Aperture mode, Auto ISO, handheld and JPEG for
                        minimal distraction.
                      </IntroText>
                      {/* <IntroText>
                        Read more about my photograpy and editing tools{' '}
                        <a href="/writing">here</a>.
                      </IntroText> */}
                    </IntroSection>
                  </PhotographyPadding>
                  <Photos>
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image2.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                    <OnePhoto
                      fluid={this.props.data.image3.childImageSharp.fluid}
                    />
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image5.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image6.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                    <OnePhoto
                      fluid={this.props.data.image7.childImageSharp.fluid}
                    />
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image8.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image10.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                    <OnePhoto
                      fluid={this.props.data.image9.childImageSharp.fluid}
                    />
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image30.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image32.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                    <OnePhoto
                      fluid={this.props.data.image11.childImageSharp.fluid}
                    />
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image13.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image14.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                    <OnePhoto
                      fluid={this.props.data.image16.childImageSharp.fluid}
                    />
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image31.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image33.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                    <OnePhoto
                      fluid={this.props.data.image15.childImageSharp.fluid}
                    />
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image17.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image18.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                    <OnePhoto
                      fluid={this.props.data.image25.childImageSharp.fluid}
                    />
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image19.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image20.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                    <OnePhoto
                      fluid={this.props.data.image26.childImageSharp.fluid}
                    />
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image21.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image22.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                    <OnePhoto
                      fluid={this.props.data.image27.childImageSharp.fluid}
                    />
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image23.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image24.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                    <OnePhoto
                      fluid={this.props.data.image28.childImageSharp.fluid}
                    />
                    <TwoPhotos>
                      <StyledImg
                        fluid={this.props.data.image29.childImageSharp.fluid}
                      />
                      <StyledImg
                        fluid={this.props.data.image4.childImageSharp.fluid}
                      />
                    </TwoPhotos>
                  </Photos>
                  <PhotographyPadding>
                    <End>
                      <IntroText>Thanks for taking a look.</IntroText>
                      <IntroText>
                        You can follow me in{' '}
                        <a href="https://www.instagram.com/oscrse">Instagram</a>{' '}
                        for regular updates.
                      </IntroText>
                      <IntroText>
                        I also post certain photos on{' '}
                        <a href="https://unsplash.com/@oscrse">Unsplash</a>,
                        free for anyone to use for any purpose. For other
                        licensing asks, send me and{' '}
                        <a href="mailto:oscareriknilsson@gmail.com">email</a>.
                      </IntroText>
                      <Buttons>
                        <Button
                          onPressed={() => {
                            window.open('https://www.instagram.com/oscrse')
                          }}
                          showArrow={true}
                        >
                          Instagram
                        </Button>
                        <Button
                          onPressed={() => {
                            window.open('https://unsplash.com/@oscrse')
                          }}
                          showArrow={true}
                        >
                          Unsplash
                        </Button>
                      </Buttons>
                    </End>
                  </PhotographyPadding>
                  <TransitionOverlay pose={getTransitionStates(status)} />
                </PageTransitionStates>
              )
            }}
          </TransitionState>
          <Footer />
        </ContentPage>
      </Page>
    )
  }
}

export default PageTwo

export const pageQuery = graphql`
  query {
    image: file(relativePath: { eq: "horse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image2: file(relativePath: { eq: "rover.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image3: file(relativePath: { eq: "wall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image4: file(relativePath: { eq: "river.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image5: file(relativePath: { eq: "tunnel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image6: file(relativePath: { eq: "eaglespoint.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image7: file(relativePath: { eq: "fog.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image8: file(relativePath: { eq: "cliff.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image9: file(relativePath: { eq: "devils.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image10: file(relativePath: { eq: "bia.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image11: file(relativePath: { eq: "bigsur.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image12: file(relativePath: { eq: "dog.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image13: file(relativePath: { eq: "fjall.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image14: file(relativePath: { eq: "fjall2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image15: file(relativePath: { eq: "fjall3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image16: file(relativePath: { eq: "goldengate.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image17: file(relativePath: { eq: "gradient.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image18: file(relativePath: { eq: "ice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image19: file(relativePath: { eq: "morning.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image20: file(relativePath: { eq: "norr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image21: file(relativePath: { eq: "seattle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image22: file(relativePath: { eq: "seattle2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image23: file(relativePath: { eq: "seattle3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image24: file(relativePath: { eq: "tracks.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image25: file(relativePath: { eq: "vessla.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image26: file(relativePath: { eq: "home.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image27: file(relativePath: { eq: "road.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image28: file(relativePath: { eq: "reykjavik.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image29: file(relativePath: { eq: "tore.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image30: file(relativePath: { eq: "fluff.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image31: file(relativePath: { eq: "clouds.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image32: file(relativePath: { eq: "macan2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    image33: file(relativePath: { eq: "tam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2560) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
