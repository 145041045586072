import React from 'react'
import styled from 'styled-components'

const StyledIntroSection = styled.div`
  max-width: 500px;
  text-align: center;
  margin: 2rem auto;
  @media (max-width: 768px) {
    text-align: left;
  }
`

const IntroSection = ({ children }) => (
  <StyledIntroSection>{children}</StyledIntroSection>
)

export default IntroSection
